<template>
    <div class="pipeline-list-box text-break">
        <div class="pipeline__board" v-for="pipeline in pageState.pipelines" :class="{'rejected' : pipeline.id === 'rejected', 'selected' : pipeline.id === 'selected' }" :key="pipeline.id">
            <div class="pipeline__board--title">
                <h5>{{ $t(pipeline.name) }}</h5>
                <span class="candidates-number" :class="{'no-candidate' : pipeline.applicants.length == 0}">{{ pipeline.applicants.length }}</span>
            </div>

            <component :is="htmlTag" :force-fallback="true" :scroll-sensitivity="200" class="pipeline__board--content" :list="pipeline.applicants" group="people" tag="ul" ghost-class="ghost" chosen-class="chosen" drag-class="drag" @start="checkShowCandidateLink" :move="move" @end="changePipelineCandidate" filter=".disable-empty-drag-item" delay="100" :delay-on-touch-only="true">

                <template v-if="pipeline.applicants.length > 0">
                    <li class="mb-2" v-for="(applicant, key) in pipeline.applicants" :key="applicant.id" :applicant-id="applicant.id" :pipeline-id="pipeline.id">
                        <div class="pipeline-card" @click.prevent="showCandidateDetails(applicant)" :key="applicant.id" :applicant-id="applicant.id" :pipeline-id="pipeline.id">
                            <div class="user__image">
                                <img :src="applicant.image" @error="loadApplicantErrorImage(applicant)" alt="" class="w-100 img-fluid"/>
                            </div>
                            <div class="user__details">
                                <router-link :to="{name: 'applicant.details', params: {id:applicant.id } }"> <span class="user__name">{{ applicant.user.name }}</span></router-link>
                                <div class="d-flex">
                                    <span class="user__address mr-1">{{ applicant.user.nationality }}</span>
                                    <p class="user__experience">{{ applicant.total_experience }} Years</p>
                                </div>
                                <div class="d-flex justify-content-between mt-2 align-items-center">
                                    <ai-score-progress-bar
                                        :is-pipeline="true"
                                        :sort-by="pageState.sortBy"
                                        :applicant="applicant"
                                        :show-in-bottom="key === 0"
                                    />
                                    <div class="application-duration">{{ applicant.updated_diff }}</div>
                                </div>
                                <div class="d-flex flex-wrap" style="z-index: -1;" @click.stop="showApplicantAssessment(applicant)" v-if="applicant.assessments.length>0">
                                    <span class="label label-primary mr-1 mt-1" :class="applicant.assessments[0].class_name" :title="applicant.assessments[0].name">{{ applicant.assessments[0].name | shortText }}</span>
                                    <span  v-if="applicant.assessments.length>1" class="label label-primary mr-1 mt-1">+ {{applicant.assessments.length-1 }} more</span>
                                </div>
                            </div>
                            <div class="user__ratting info-button-light" :class="{'disabled' : applicant.rating === 0 }"><i class="eicon e-star"></i>
                                <template v-if="applicant.rating > 0">{{ applicant.rating }}</template>
                            </div>
                        </div>
                    </li>
                </template>

                <li class="pipeline-card disable-empty-drag-item" v-else>
                    <p>{{ $t("No Candidates Found") }}</p>
                </li>

            </component>

        </div>
    </div>

</template>

<script>

import { mapState, mapActions } from 'vuex';
import draggable from "vuedraggable";
import client from "../../../app/api/Client";
const AiScoreProgressBar = () => import("../../../components/jobs/AiScoreProgressBar");
import {DEFAULT_PIPELINE, PERMISSIONS} from '../../../constants/enums';
import {UPDATE_CANDIDATE_PIPELINE_ORDER, UPDATE_PAGE_STATE} from "../../../constants/action-type";
import {canOrganizeCandidate} from "../../../config/permission";


export default {
    name: "BoardViewCandidate",
    components: {
        AiScoreProgressBar,
        draggable
    },
    data() {
        return {
            showCandidateLink: true,
            htmlTag: 'ul'
        }
    },
    computed: {
        ...mapState(['company', 'user', 'pageState']),

        canOrganizeCandidate() {
            return canOrganizeCandidate();
        }
    },
    filters: {
        shortText: function (value) {
            if (!value) return '';
            if (value === undefined) return '';
            if (value.length > 15) {
                return value.slice(0, 15) + '...';
            }
            return value;
        }
    },
    methods: {
        ...mapActions([UPDATE_PAGE_STATE, UPDATE_CANDIDATE_PIPELINE_ORDER]),

        removeHighlightedPipelineBorder(e) {
            document.querySelectorAll('.dropable').forEach((el) => {
                el.classList.remove('dropable');
            });
        },

        move(event) {
            this.removeHighlightedPipelineBorder();
            event.to.classList.add('dropable');
        },

        async changePipelineCandidate(evt) {

            this.removeHighlightedPipelineBorder();

            if (!this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                this.$toast.error(this.$t(`Sorry you do not have permission for this action.`));
                return;
            }

            let applicantId = evt.item.getAttribute('applicant-id');
            if(applicantId == null) {
                return;
            }

            let prevPipelineId = evt.item.getAttribute('pipeline-id');
            let pipelineId = evt.to.children[0].getAttribute('pipeline-id');

            let moveToPipeline = _.find(this.pageState.pipelines, function(pipeline) {
                return pipeline.id == pipelineId
            });
            this.UPDATE_PAGE_STATE({'moveToPipeline': moveToPipeline, 'prevPipelineId': prevPipelineId});

            if ((pipelineId === DEFAULT_PIPELINE.SELECTED) && (prevPipelineId != pipelineId)) {
                this.UPDATE_PAGE_STATE({'showOnBoardingModal':true, 'selectedApplicant': [applicantId], 'oldDraggableIndex': evt.oldDraggableIndex});
            } else if ((pipelineId == DEFAULT_PIPELINE.REMOTE_INTERVIEW) && (prevPipelineId != pipelineId)) {
                this.UPDATE_PAGE_STATE({'showRemoteInterviewModal':true, 'selectedApplicant': [applicantId], 'oldDraggableIndex': evt.oldDraggableIndex});
            } else {
                this.isLoading = true;

                try {

                    if(prevPipelineId != pipelineId) {
                        let  addCandidateData = {
                            applicants: [applicantId],
                            pipeline: pipelineId,
                            last_applicant: false
                        }

                        if(this.pageState.sortBy !== '') {
                            addCandidateData.last_applicant = true;
                        }

                        await client().post(`/job/${this.$route.params.jobId}/pipeline/candidate`, addCandidateData);
                    }

                    let applicants = moveToPipeline.applicants.map(({id}, order) => {
                        return {
                            'applicant_id':id,
                            order
                        }
                    });

                    if (this.pageState.sortBy === '') {
                        let {data: {data: candidatePipelineOrders}} = await client().post(`/job/${this.$route.params.jobId}/pipeline/candidate/re-order`, {
                            'applicants': applicants,
                        });

                        this.UPDATE_PAGE_STATE({'candidatePipelineOrders' : candidatePipelineOrders});

                        this[UPDATE_CANDIDATE_PIPELINE_ORDER]();

                    }

                } catch (error) {
                    this.$toast.error(this.$t(error.response.data.message));
                    if (error.response.status === 480 || error.response.status === 490) {
                        await this.$router.push({name: 'jobs.published'});
                    }
                }
                this.isLoading = false;
            }

            this.showCandidateLink = true;
        },

        showCandidateDetails(applicant) {
            if(this.showCandidateLink) {
                if (event.ctrlKey || event.metaKey) {
                    let routeData = this.$router.resolve({name: 'applicant.details', params: {id: applicant.id}});
                    window.open(routeData.href, '_blank');
                } else {
                    return this.$router.push({name: 'applicant.details', params: {id: applicant.id}});
                }
            }
        },

        checkShowCandidateLink(evt) {
            this.showCandidateLink = false;
        },

        showApplicantAssessment(applicant){
            return this.$router.push({name: 'applicant.details', params: {id: applicant.id},query: {tab: 'evaluation-assessment'}});
        }
    },
    created() {
        if(this.canOrganizeCandidate) {
            this.htmlTag = 'draggable';
        }
    }
}
</script>


<style scoped>
.chosen {
    opacity: 0.5;
}

.chosen.ghost {
    opacity: 0;
}
.drag {
    opacity: 0.5;
}

.application-duration {
    flex: 1 0 auto;
}

.page-body .content-area__body .pipeline-section .pipeline-list-box .pipeline__board {
    margin-right: 20px !important;
}

.page-body .content-area__body .pipeline-section .pipeline-list-box .pipeline__board--content {
    height: 500px;
}
.page-body .content-area__body .pipeline-section .pipeline-list-box .pipeline__board--content li {
    position: relative;
}

.page-body .content-area__body .pipeline-section .pipeline-list-box .pipeline__board--content.dropable {
    -webkit-box-shadow: inset 0 0 5px rgb(88 125 252);
    -moz-box-shadow: inset 0 0 5px rgb(88 125 252);
    box-shadow: inset 0 0 5px rgb(88 125 252);
}

.pipeline-card {
    border: 1px solid rgba(88, 125, 252, .2);
    box-shadow: 0 2px 5px rgba(88, 125, 252, .2);
    cursor: pointer;
}

.page-body .content-area__body .pipeline-section .pipeline-list-box .pipeline__board--content .pipeline-card .user__details .user__name {
    display: inline-block;
}

.section-title {
    text-transform: unset;
}

.pipeline-card .user__ratting {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: .8em;
}

.pipeline-card .user__ratting i.eicon.e-star {
    font-size: .8em;
}

.ai-score-progress {
    width: 100px;
}

.pipeline-card:hover {
    color: inherit;
    box-shadow: 0 5px 10px rgba(88, 125, 252, .3);
}

span.candidates-number.no-candidate {
    background: #c6d3fd !important;
}

.label-primary {
    background: rgba(89,125,252,0.15);
    color: #597dfc;
    font-size: 0.725rem;
    border-radius: 50px;
    padding: 5px 6px;
    line-height: 1;
    font-weight: 500;
    display: inline-block;
}

.ended {
    background: rgba(96,206,131,0.15)!important;
    color: #60ce83;

}

.not-completed {
    color: #ff5f74;
    background: rgba(255,95,116,0.15) !important;
}
.applicant-menu {
    position: absolute;
    top: 12px;
    right: 40px;
    font-size: .8rem;
    color: #7d8091;

}
</style>
